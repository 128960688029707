import {
  SetupCardPageLayout,
  SpinnerPage,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@affinidi/component-library'
import { FlowError } from '@ory/client'
import { ReactElement, useEffect, useState } from 'react'

import * as S from './Error.styled'
import { sdk } from '../sdk'

const NOT_FOUND = 404
const FORBIDDEN = 403
const GONE = 410

export const Error = (): ReactElement => {
  const { getValueByScreenSize } = useMediaQuery()
  const [error, setError] = useState<FlowError>()

  const pVariant = getValueByScreenSize<TypographyProps['variant']>({
    default: 'p2',
    desktopXL: 'p1',
  })

  const urlParams = new URLSearchParams(window.location.search)
  let id = urlParams.get('id')
  let errorCode = urlParams.get('error')
  let error_description = urlParams.get('error_description')

  let loading = true

  const oauth2LoginRequest = localStorage.getItem('oauth2_login_request_client')

  useEffect(() => {
    if (id) {
      sdk
        .getFlowError({ id: String(id) })
        .then(({ data }) => {
          if (oauth2LoginRequest !== null && oauth2LoginRequest !== 'undefined') {
            const redirectUri = getRedirectUriFromLoginRequest(oauth2LoginRequest)
            const errorReason = (data.error as any)?.reason
            const errorMessage = (data.error as any)?.message
            const redirectUrl = `${redirectUri}?error=${errorMessage}&error_description=${errorReason}`
            window.location.href = redirectUrl
          }
          setError(data)
        })
        .catch((err: any) => {
          switch (err.response?.status) {
            case NOT_FOUND:
            // The error id could not be found. Let's just redirect home!
            case FORBIDDEN:
            // The error id could not be fetched due to e.g. a CSRF issue. Let's just redirect home!
            case GONE:
              // The error id expired. Let's just redirect home!
              return (window.location.href = '/login')
            default:
              return (window.location.href = '/login')
          }
        })
    } else if (errorCode && error_description) {
      if (oauth2LoginRequest !== null && oauth2LoginRequest !== 'undefined') {
        const redirectUri = getRedirectUriFromLoginRequest(oauth2LoginRequest)
        const redirectUrl = `${redirectUri}?error=${errorCode}&error_description=${error_description}`
        window.location.href = redirectUrl
      }
      setError({ error: { status: errorCode, reason: error_description } } as any)
    }
  }, [id])

  if (loading) {
    return <SpinnerPage isDark />
  }

  return (
    <SetupCardPageLayout cardTitle="Error">
      <S.ErrorInfo>
        <S.ErrorTitle variant={pVariant}>{error ? (error?.error as any)?.status : 'Error Occured'}</S.ErrorTitle>
        <Typography variant={pVariant}>{error ? (error?.error as any)?.reason : ''}</Typography>
      </S.ErrorInfo>

      <S.SupportBlock variant={pVariant}>
        Please try again or raise a{' '}
        <a href="https://share.hsforms.com/1i-4HKZRXSsmENzXtPdIG4g8oa2v" target="_blank" rel="noopener noreferrer">
          Support Ticket
        </a>
        .
      </S.SupportBlock>
    </SetupCardPageLayout>
  )
}
function getRedirectUriFromLoginRequest(oauth2LoginRequest: string) {
  const oauth2LoginRequestObj = JSON.parse(oauth2LoginRequest)
  const oauth2Client = oauth2LoginRequestObj
  const redirectUri = oauth2Client.redirect_uris[0]
  return redirectUri
}
