import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Dashboard } from './Dashboard/Dashboard'
import { Error } from './Error/Error'
import { Login } from './Login/Login'

export const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/error" element={<Error />} />
      </Routes>
    </BrowserRouter>
  )
}
