const createConfig = (env: string | undefined) => {
  switch (env) {
    case 'staging':
      throw new Error('staging is not enabled')
    case 'prod':
      return {
        env,
        orySdkURL: 'https://apse1.auth.developer.affinidi.io/idp',
        affindiID: 'affinidi',
      }
    case 'dev':
      return {
        env,
        orySdkURL: 'https://apse1.dev.auth.developer.affinidi.io/idp',
        affindiID: 'affinidi',
      }
    default:
      return {
        env,
        orySdkURL: 'https://apse1.auth.developer.affinidi.io/idp',
        affindiID: 'affinidi',
      }
  }
}

export default createConfig(process.env.REACT_APP_ENVIRONMENT)
