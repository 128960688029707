import { Typography, pxToRem } from '@affinidi/component-library'
import styled from 'styled-components'

const THIRTY_TWO = 32
const SIXTEEN = 16
const TWENTY_FOUR = 24

export const ErrorInfo = styled.div`
  margin-top: ${pxToRem(THIRTY_TWO)};
`

export const ErrorTitle = styled(Typography)`
  margin-bottom: ${pxToRem(SIXTEEN)};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.utility.danger['100']};
`

export const SupportBlock = styled(Typography)`
  margin-top: ${pxToRem(TWENTY_FOUR)};
  padding-top: ${pxToRem(TWENTY_FOUR)};
  border-top: 1px solid ${({ theme }) => theme.colors.brand.secondary['10']};

  & a {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`
