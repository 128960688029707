import { SetupCardPageLayout, SetupCardButton, SpinnerPage } from '@affinidi/component-library'
import { LoginFlow, UiNode } from '@ory/client'
import { FilterFlowNodes } from '@ory/elements'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LoginButtonContent } from './Login.styled'
import { AffinidiLogo } from '../assets/images/affinidiLogo'
import config from '../config'
import { sdk, sdkError } from '../sdk'

export const Login = (): JSX.Element => {
  const [flow, setFlow] = useState<LoginFlow | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const formRef: RefObject<HTMLFormElement> = useRef() as RefObject<HTMLFormElement>

  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        // the flow data contains the form fields, error messages and csrf token
        .getLoginFlow({ id: flowId })
        .then(({ data: f }) => {
          f.oauth2_login_request &&
            localStorage.setItem('oauth2_login_request_client', JSON.stringify(f.oauth2_login_request.client))
          if (!isErrorFlow(f)) {
            setFlow(f)
          }
        })
        .catch(sdkErrorHandler),
    [],
  )

  // TODO: Remove this we don't have to create one. We get the flow direcly from the URL
  // Create a new login flow
  const createFlow = () => {
    const aal2 = searchParams.get('aal2')
    sdk
      // aal2 is a query parameter that can be used to request Two-Factor authentication
      // aal1 is the default authentication level (Single-Factor)
      // we always pass refresh (true) on login so that the session can be refreshed when there is already an active session
      .createBrowserLoginFlow({ refresh: true, aal: aal2 ? 'aal2' : 'aal1' })
      // flow contains the form fields and csrf token
      .then(({ data: f }) => {
        // Update URI query params to include flow id
        setSearchParams({})
        // Set the flow data
        setFlow(f)
        localStorage.setItem('oauth2_login_request_client', JSON.stringify(f.oauth2_login_request?.client))
      })
      .catch(sdkErrorHandler)
  }

  // initialize the sdkError for generic handling of errors
  const sdkErrorHandler = sdkError(getFlow, createFlow, setFlow, '/login', true)

  const manual = searchParams.get('manual')
  const isMicrosoft = searchParams.get('microsoft')

  useEffect(() => {
    // extract login challenge and redirect to kratos public endpoint to get flowId on /login
    const loginChallenge = searchParams.get('login_challenge')
    if (loginChallenge) {
      window.location.replace(
        `${config.orySdkURL}/self-service/login/browser?aal=&refresh=&return_to=&organization=&login_challenge=${loginChallenge}`,
      )
      return
    }

    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams.get('flow')
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow) // if for some reason the flow has expired, we need to get a new one
      return
    }

    // TODO: check if we really need this
    // we assume there was no flow, so we create a new one
    createFlow()
  }, [])

  // auto submit form
  useEffect(() => {
    if (flow && formRef.current && !manual && !isSubmitting) {
      setIsSubmitting(true)
      formRef.current.submit()
    }
  }, [flow, formRef.current])

  if (!flow) {
    return <SpinnerPage isDark text={'Loading'} />
  }

  // TODO: Create a seperate component for this
  const affinidiNode = flow.ui.nodes.filter(filterAffinidiNode())[0]
  const affinidiProviderName = (affinidiNode.attributes as any).name
  const affinidiProviderValue = isMicrosoft ? 'microsoft' : (affinidiNode.attributes as any).value
  const clientName = flow.oauth2_login_request?.client.client_name
  const cardDescription = `To authenticate ${clientName ? clientName : 'Affinidi Elements'}.`
  return (
    <>
      <div hidden={!manual}>
        <SetupCardPageLayout cardTitle="Log in" cardDescription={cardDescription}>
          <form
            id="ssoSignin"
            ref={formRef}
            action={flow.ui.action}
            method={flow.ui.method}
            onSubmit={() => {
              setIsSubmitting(true)
            }}
          >
            <input hidden={true} name={affinidiProviderName} value={affinidiProviderValue}></input>
            <SetupCardButton loading={isSubmitting} type="submit">
              <LoginButtonContent>
                <AffinidiLogo />
                <div> &nbsp; {isMicrosoft ? '' : 'Affinidi'} Login </div>
              </LoginButtonContent>
            </SetupCardButton>
            <FilterFlowNodes
              filter={{
                nodes: flow.ui.nodes,
                groups: 'default', // we only want to map hidden default fields here
                attributes: 'hidden',
              }}
              includeCSRF={true}
            />
          </form>
        </SetupCardPageLayout>
      </div>
      <div hidden={!!manual}>
        <SpinnerPage isDark text={'Initiating login session'} />
      </div>
    </>
  )
}

function filterAffinidiNode() {
  return (node: UiNode) => (node.attributes as any)?.value === config.affindiID
}

function parseOpenIDError(errorString: string): { error: string; error_description: string } {
  const errorMatch = errorString.match(/OpenID Provider returned error "(.*?)":\s*(.*)/)

  const ONE = 1
  const TWO = 2
  const THREE = 3
  if (errorMatch && errorMatch.length === THREE) {
    return {
      error: errorMatch[ONE],
      error_description: errorMatch[TWO],
    }
  }

  return {
    error: 'unknown',
    error_description: 'unknown_error',
  }
}

function isErrorFlow(flow: LoginFlow): boolean {
  if (flow.oauth2_login_request) {
    if (flow.ui.messages && flow.ui.messages.length > 0) {
      const messageId = 4000001
      const message = flow.ui.messages.filter((m) => m.id === messageId)[0]
      if (message && flow.oauth2_login_request.client.redirect_uris) {
        const { error, error_description } = parseOpenIDError(message.text)
        const authUrl = new URL(flow.oauth2_login_request.request_url)
        const params = new URLSearchParams(authUrl.search)
        const callbackUrl = params.get('redirect_uri')
        let state = params.get('state')
        if (state) {
          state = encodeURIComponent(state)
        }
        window.location.href = `${callbackUrl}?error=${error}&error_description=${error_description}&state=${state}`
        return true
      }
    }
  }

  return false
}
