import { theme } from '@affinidi/component-library'
import { IntlProvider } from '@ory/elements'

// optional global css reset

import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'

import './index.scss'
import { App } from './App'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    {/* We add the Ory themes here */}
    <ThemeProvider theme={theme}>
      <IntlProvider>
        <App />
      </IntlProvider>
    </ThemeProvider>
  </React.StrictMode>,
)
