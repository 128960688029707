export const AffinidiLogo = (): JSX.Element => {
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAwCAYAAACrF9JNAAAAAXNSR0IArs4c6QAAAl1JREFUaEPtms1SgzAQx0lLR+pJvelRHwSYOuP4Cr5MwY8H0Bdq6E3fwaue/DhZndLGWVpsyvCxBAiJlCPNJvvLbpp/whKjpqdnHnnQFSF7NukPnbRul+GnD++Xxhc1wm+KHdq0Thm2bVo7UsUYwHrmwVi0D7aYUcZ+gmX4Hk1Q1iMf0rScPjkcZ0VLFBiinAUrFbI/OJ7UDZeclDB8cZOpLAfStBzTPJmIRqmsHaTxYv7qxnaNQ1Zdd2UB4/YRKPvwIaqNQspIz6JJgPStmkXZ/66m5fSM/dStoMixun+3HdsOaBCI9ltpCxEdtKwdY4y5rutSStF7Kz+GNpAACKBlJygSKCJGsm0gkjCmKKhWkAAqkrbaQYpE8w8yFtiyUxEz3mL+tqWPR6NLn06fMKZRmxWkZEWD9m7dcD573jIJpo/G+cUVupsIsi1Vg/UyCQl2AAmwmCeCrCqbMANVaZMGeX17b9zcPaC6JaqnKlCkQcL7wfAMB6l6qtYCqYIILwpHViSxKUtUX495kURDFs2iCr/Hsi7pi+/7vud5ufdDm31SBZIcH3aQu0iu0kM7gc5n9W5NcrPRjS1EZzGAFemkE7JOV4Fe5kzZkaOWpodm7DFrs0924fojvgJRVcLWc5GlKt3aL16gV7qSVJmTh/z3l8tYrZoMmDYCXSRNY1htIAkhwr7uPsKqIN6b/ZweJ3RLQoGvAKl6o4jL84YKlLK2LeklLrwjTR/L+LIWflw5kUxMed2wWXCbFdNyAWFeVWSRiorq6RAVk61EMs15/kt1WuVkXBEJthiwOtP1F+VEe4wNzA9zAAAAAElFTkSuQmCC"
      className="playground_modal--thumbnail--LFYpK"
      width="28.5"
      height="24"
      alt="Playground thumbnail"
    ></img>
  )
}
